<template>
  <div class="navbar py-2 shadow-md lg:py-3">
    <div class="navbar-start">
      <div class="dropdown">
        <label tabindex="0" class="btn btn-ghost lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </label>
        <ul tabindex="0"
          class="font-bold menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
          <li><router-link to="/file-converter">File Converter</router-link></li>
          <li><router-link to="/video-converter">Video Converter</router-link></li>
          <li><router-link to="/image-converter">Image Converter</router-link></li>
        </ul>
      </div>
      <router-link to="/" class="btn btn-md btn-primary normal-case">FileFlexer</router-link>
    </div>


    <div class="navbar-center hidden lg:flex">
      <router-link to="/file-converter" class="btn btn-md btn-ghost normal-case">File Converter</router-link>
      <router-link to="/video-converter" class="btn btn-md btn-ghost normal-case">Video Converter</router-link>
      <router-link to="/image-converter" class="btn btn-md btn-ghost normal-case">Image Converter</router-link>
    </div>

    <div class="navbar-end">
      <button class="btn btn-md btn-success normal-case">Get Started</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>
