import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FileConverter from "../views/FileConverter.vue"
import ImageConverter from "../views/ImageConverter.vue"
import VideoConverter from "../views/VideoConverter.vue"



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/file-converter',
    name: 'file',
    component: FileConverter
  },
  {
    path: '/image-converter',
    name: 'image',
    component: ImageConverter
  },
  {
    path: '/video-converter',
    name: 'video',
    component: VideoConverter
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
