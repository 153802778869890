<template>
  <div id="app">
    <NavBar />
    <router-view />
  </div>
</template>


<script>
import NavBar from './components/NavBar.vue';

export default {
  name:"AppVue",
  components: {
    NavBar,
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
